
import { defineComponent, PropType } from 'vue'
import { Settings, Theme } from '@/entities/Settings'

export default defineComponent({
  props: {
    settings: {
      required: true,
      type: Settings as PropType<Settings>
    }
  },

  emits: [
    'saveSettings',
    'close'
  ],

  methods: {
    updateResponseTime (event: Event) {
      const target = event.target as HTMLInputElement
      this.settings.setResponseTime(Number(target.value))
      this.$emit('saveSettings')
    },

    updateShameTime (event: Event) {
      const target = event.target as HTMLInputElement
      this.settings.setShameTime(Number(target.value))
      this.$emit('saveSettings')
    },

    updateShameRounds (event: Event) {
      const target = event.target as HTMLInputElement
      this.settings.setShameRounds(target.checked)
      this.$emit('saveSettings')
    },

    updateModImmunity (event: Event) {
      const target = event.target as HTMLInputElement
      this.settings.setModImmunity(target.checked)
      this.$emit('saveSettings')
    },

    updateAllowVoteChange (event: Event) {
      const target = event.target as HTMLInputElement
      this.settings.setAllowVoteChange(target.checked)
      this.$emit('saveSettings')
    },

    resetHighScore () {
      this.settings.setHighScore(null, null)
      this.$emit('saveSettings')
    },

    updateTheme () {
      const themes = Object.values(Theme)
      const theme = themes[themes.findIndex(theme => theme === this.settings.theme) + 1] ?? themes[0]
      this.settings.setTheme(theme)
    }
  }
})
