
import { defineComponent, PropType } from 'vue'
import { ClientId, Uri } from '@/constants'

export default defineComponent({
  props: {
    token: {
      required: false,
      default: null,
      type: String as PropType<null | string>
    }
  },

  computed: {
    logInUrl () {
      const params = new URLSearchParams({
        client_id: ClientId,
        redirect_uri: Uri,
        response_type: 'token id_token',
        scope: 'openid moderator:manage:banned_users'
      })

      return new URL(`https://id.twitch.tv/oauth2/authorize?${params}`)
    },

    url (): string {
      return `${Uri}#/play/${this.token}`
    }
  },

  methods: {
    clipboard () {
      navigator.clipboard.writeText(this.url)
    }
  }
})
