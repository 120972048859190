import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GameBoard = _resolveComponent("GameBoard")!

  return (_ctx.status === _ctx.Status.ready && _ctx.chat)
    ? (_openBlock(), _createBlock(_component_GameBoard, {
        key: 0,
        "color-generator": _ctx.colorGenerator,
        random: _ctx.random,
        chat: _ctx.chat,
        settings: _ctx.settings,
        onShameUser: _ctx.shameUser,
        onSaveSettings: _ctx.saveSettings
      }, null, 8, ["color-generator", "random", "chat", "settings", "onShameUser", "onSaveSettings"]))
    : _createCommentVNode("", true)
}